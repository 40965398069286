import cookieValue from 'querystring'
import React, { useCallback, useState, FC, useEffect } from 'react'
import { getParentDepartment } from '@moonpig/web-core-utils'
import { getBrowserCookies } from '@moonpig/web-core-cookies'
import { Box, Flex } from '@moonpig/launchpad-components'
import { styled, breakpointUp, breakpointDown } from '@moonpig/launchpad-utils'
import { system as s } from '@moonpig/launchpad-system'
import { Breadcrumbs } from '@moonpig/web-core-nav'
import {
  useHandleAddToBasket,
  AddedToBasketEvent,
  DispatchBanner,
  useProductModalEvents,
  useBasket,
  DispatchCutOffQuery,
} from '@moonpig/web-shared-products'
import { useCustomerId } from '@moonpig/web-shared-utils'
import { trackGAEvent } from '@moonpig/web-core-analytics'
import { Region } from '@moonpig/web-core-types'
import {
  DepartmentsEnum,
  SponsoredProductInput,
} from '@moonpig/web-explore-types-graphql'
import { useExperiment } from '@moonpig/web-core-experiments'
import { CmsContent, SearchFiltersFacet } from '../../pages/GalleryPage/types'
import { generateBreadcrumbsClickGAEvent } from '../../analytics/GAEvents'
import { GalleryProduct } from '../ProductGridWithPaging'
import { InternalUserGalleryInfo } from '../InternalUserGalleryInfo'
import { FixedBottomBar } from '../FixedBottomBar'
import { SoldOutGalleryMessage } from './SoldOutGalleryMessage'
import { Header } from './Header'
import { ProductGridWithTracking } from '../ProductGridWithTracking'
import { useProductListingPageContext } from '../../utils/productListingPageContext'
import { OfferBanner } from '../OfferBanner'
import {
  getSortByOptions,
  useResetProductListing,
  useSetUIHCookie,
} from '../../utils'
import { LoadMoreType } from '../types'
import { GALLERY_PAGE_TYPE } from '../../constants'
import { useFindLocaleText } from '../../text-localisation'
import { usePromoTile } from './usePromoTile'
import { QuickFilters } from '../QuickFilters/QuickFilters'
import { FiltersMenu } from '../FiltersMenu'
import { FiltersCarousel } from '../FiltersCarousel'
import { useSearchStore } from '../../store/SearchStore'

const StyledBox = styled(Box)`
  ${s({
    bgcolor: 'colorBackground01',
  })}
`

const StyledHeaderContainer = styled(Box)`
  ${s({
    paddingTop: 4,
    paddingLeft: { xs: 6, lg: 10, xxl: 12 },
    paddingRight: 6,
  })}
`

const StyledFlex = styled(Flex)`
  width: 100%;
  flex-direction: row;

  ${breakpointDown('md')} {
    flex-direction: column;
  }
`

const StyledGridContainer = styled.div`
  width: 100%;
  position: relative;
`

const TrackableDiv = styled.div`
  flex: 0 0 auto;
  ${s({
    mb: 4,
  })}
`

const StyledTextContainer = styled(Box)`
  ${breakpointUp('md')} {
    ${s({
      p: 4,
      m: 6,
      minHeight: '66px',
    })}
  }

  a {
    ${s({
      typography: 'typeBodyLabel',
    })}
  }
`

export type GalleryDetails = {
  region: Region
  url: string
  department: DepartmentsEnum[]
  searchTerm: string
  promotionId?: string
  sponsoredProducts: SponsoredProductInput[]
}

type GalleryProps = {
  details: GalleryDetails
  content: CmsContent
  metaTitle: string
  totalCount: number
  products: GalleryProduct[]
  loadMoreProducts: (loadMoreType: LoadMoreType) => Promise<boolean>
  presetFacets: SearchFiltersFacet[]
  experiments?: { [key: string]: string } | undefined
  dispatchCutOffData?: DispatchCutOffQuery
  shouldDisplaySoldOutMessage?: boolean
  groupCardProject?: string
  totalClickRankDocumentCount: number
}

export const Gallery: FC<GalleryProps> = ({
  details,
  content,
  totalCount,
  products,
  loadMoreProducts,
  presetFacets,
  experiments,
  dispatchCutOffData,
  shouldDisplaySoldOutMessage,
  metaTitle,
  groupCardProject,
  totalClickRankDocumentCount,
}) => {
  useResetProductListing(0)
  const [_, { setLoading, setSuccess, setError }] = useBasket()
  const customerId = useCustomerId()
  const localiseText = useFindLocaleText()
  const [state] = useProductListingPageContext()
  const { setUIHCookie } = useSetUIHCookie()
  const { loading } = state
  const [isRecentlyViewedOpen, setRecentlyViewedOpen] = useState(false)
  const selectedFilters = useSearchStore(store => store.selectedFilters)
  const preAppliedFilters = useSearchStore(store => store.preAppliedFilters)

  const useDropdownFilters =
    useExperiment('search-use-dropdown-filters')?.toLocaleLowerCase() ===
    'enabled'

  useProductModalEvents(event => {
    if (event.type === 'PRODUCT_MODAL_CLOSED') {
      setRecentlyViewedOpen(true)
    }
  })

  const { title, description, breadcrumbsItems, offer } = content
  const { region, promotionId, searchTerm, department } = details

  const cookies = getBrowserCookies()

  const responsiveSettingsData = cookies.responsiveSettings

  const offerBanner = OfferBanner({ offerText: offer })

  const { EnableInternalUserMode: enableInternalUserMode = 'false' } =
    cookieValue.parse(responsiveSettingsData)
  const isInternalUser = /^true$/i.test(enableInternalUserMode as string)

  const soldOutMessage = localiseText('find.sold_out')
  const itsNotYouMessage = localiseText('find.its_not_you')

  const { handleAddToBasket, addingToBasket } = useHandleAddToBasket({
    pageLocation: 'gallery',
    soldOutMessage,
    itsNotYouMessage,
    groupCardProject,
  })

  useEffect(() => {
    setLoading(addingToBasket)
  }, [addingToBasket, setLoading])

  const onAddedToBasket = useCallback(
    async (e: AddedToBasketEvent) => {
      const {
        product,
        variant,
        quantity,
        selectedAddon,
        buttonActionName,
        requiresEditingValue,
        componentName,
        redirectUser,
        giftXSellTrackingData,
      } = e
      setUIHCookie({ product })
      try {
        await handleAddToBasket({
          quantity,
          product,
          variant,
          filters: [
            ...selectedFilters.map(f => ({ facetKey: f.id, group: f.parent })),
            ...preAppliedFilters.map(f => ({
              facetKey: f.key,
              group: f.group,
            })),
          ],
          experiments: { ...experiments },
          searchTerm,
          selectedAddon,
          itemIndex: product?.productIndex || 0,
          productsLength: totalCount,
          buttonActionName,
          requiresEditingValue,
          componentName,
          redirectUser,
          giftXSellTrackingData,
        })
        setSuccess(true)
      } catch (error) {
        setError(true)
      }
    },
    [
      setUIHCookie,
      handleAddToBasket,
      selectedFilters,
      preAppliedFilters,
      experiments,
      searchTerm,
      totalCount,
      setSuccess,
      setError,
    ],
  )

  const promoTile = usePromoTile(content.promoTile)

  const dispatchCutOff =
    dispatchCutOffData?.contentDepartments[0]?.dispatchCutOff

  const sortByOptions = getSortByOptions({
    isCards: getParentDepartment(department[0]) === DepartmentsEnum.ALL_CARDS,
    localiseText,
  })

  return (
    <StyledBox>
      {((products && products.length > 0) || loading) && (
        <>
          {offerBanner ||
            (dispatchCutOff && (
              <DispatchBanner dispatchCutOff={dispatchCutOff} />
            ))}
          <StyledHeaderContainer>
            <TrackableDiv
              onClick={() => {
                trackGAEvent(generateBreadcrumbsClickGAEvent(breadcrumbsItems))
              }}
              data-testid="breadcrumb-header"
            >
              <Breadcrumbs items={breadcrumbsItems} />
            </TrackableDiv>
            <Header totalCount={totalCount} title={title} />
            {!loading &&
              description &&
              department[0] === DepartmentsEnum.DIGITAL_GIFTS && (
                <Box
                  mb={6}
                  /* eslint-disable-next-line react/no-danger */
                  dangerouslySetInnerHTML={{ __html: description }}
                  data-testid="gallery-description"
                />
              )}
          </StyledHeaderContainer>
          <StyledFlex>
            {isInternalUser && (
              <InternalUserGalleryInfo
                productCount={totalCount}
                searchTerm={searchTerm}
                filters={presetFacets}
                promotionId={promotionId}
                totalClickRankDocumentCount={totalClickRankDocumentCount}
              />
            )}
            <FiltersMenu sortOptions={sortByOptions} />
            <StyledGridContainer>
              {useDropdownFilters && <QuickFilters />}
              <FiltersCarousel />
              <ProductGridWithTracking
                totalCount={totalCount}
                pageTitle={title}
                metaTitle={metaTitle}
                details={details}
                products={products}
                loadMoreProducts={loadMoreProducts}
                onAddedToBasket={onAddedToBasket}
                isInternalUser={isInternalUser}
                presetFacets={presetFacets}
                experiments={experiments}
                pageType={GALLERY_PAGE_TYPE}
                customerId={customerId}
                groupCardProject={groupCardProject}
                promoTile={promoTile}
              />
            </StyledGridContainer>
          </StyledFlex>
          {!loading && description && (
            <StyledTextContainer>
              <div
                /* eslint-disable-next-line react/no-danger */
                dangerouslySetInnerHTML={{ __html: description }}
                data-testid="gallery-description"
              />
            </StyledTextContainer>
          )}
        </>
      )}
      {shouldDisplaySoldOutMessage && (
        <SoldOutGalleryMessage department={department} region={region} />
      )}
      <FixedBottomBar
        isOpen={isRecentlyViewedOpen}
        onClose={() => setRecentlyViewedOpen(false)}
        onOpen={() => setRecentlyViewedOpen(true)}
        pageTitle={title}
      />
    </StyledBox>
  )
}
